.KitchenLibrary {
  text-align: center;

  &__header {
    display: inline-flex;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid black;

    &__left {
      align-self: center;
    }

    &__right {
      margin-left: auto;
    }
  }
}

.bp3-menu {
  max-height: 300px;
  overflow: auto;
}
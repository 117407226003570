.AddIngredient {
  display: inline-flex;
  align-items: baseline;

  > .bp3-fill {
    margin-left: 5px;
    width: 50%;
  }

  > .bp3-numeric-input {
    margin-left: 5px;
    width: 120px;
    > .bp3-input-group {
      width: 80px;
    }
  }

  > .bp3-input-group {
    margin-left: 5px;
    margin-right: 5px;
  }

  > .bp3-control {
    margin-left: 5px;
  }
}